import { Component, BaseComponent, Prop } from '@zento-lib/components';
import { Input } from 'theme/components/Form/Input';

import type { INameForm } from './NameForm.d';
import style from './style.scss';

export type NameFormState = Record<string, any>;

/**
 * Address Form
 *
 * Address Form allowing to show inputs info for user.
 **/
@Component({})
export class NameForm extends BaseComponent<INameForm, unknown> {
  private static T = {
    firstName: 'label_first_name',
    lastName: 'label_last_name',
  };

  /**
   * Determines state prop
   */
  @Prop({ type: Object, required: true })
  state: NameFormState;

  /**
   * Determines input style
   */
  @Prop({ type: Boolean, required: false })
  inputStyle?: boolean;

  /**
   * Checks if form belongs to checkout or my account for state manipulation
   */
  @Prop({ type: Boolean, default: false })
  myAccountDetails?: boolean;

  render() {
    return (
      <div class={{ [style.inputBoxWrapper]: this.inputStyle }} key='box-first-last-name'>
        <Input
          state={this.state}
          valueKeeper={!this.myAccountDetails ? 'firstName' : 'firstname'}
          name='first-name'
          type='text'
          autofocus='true'
          validateOn='input'
          required={true}
          minLength={2}
          dataTestId='firstName'
          pattern='name'>
          <span slot='label'>{this.getTranslation({ id: NameForm.T.firstName })}</span>
        </Input>

        <Input
          state={this.state}
          valueKeeper={!this.myAccountDetails ? 'lastName' : 'lastname'}
          name='last-name'
          type='text'
          validateOn='input'
          required={true}
          minLength={2}
          dataTestId='lastName'
          pattern='name'>
          <span slot='label'>{this.getTranslation({ id: NameForm.T.lastName })}</span>
        </Input>
      </div>
    );
  }
}
